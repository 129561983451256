define('bottomline-execution/mixins/vehicle-identifier', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),

    identifier: Ember.computed('appState.useBeBackend', function () {
      if (this.get('appState.useBeBackend')) {
        return this.get('id');
      } else {
        return this.get('License');
      }
    })
  });
});