define('bottomline-execution/controllers/trips/index', ['exports', 'bottomline-execution/mixins/storages-loader', 'bottomline-execution/mixins/trip-sorter'], function (exports, _storagesLoader, _tripSorter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_storagesLoader.default, _tripSorter.default, {
    i18n: Ember.inject.service(),
    toast: Ember.inject.service(),
    internetConnection: Ember.inject.service(),
    ajax: Ember.inject.service(),
    selectedTrip: null,
    selectedTripStopId: Ember.computed.alias('appState.selectedTripStop'),
    selectedTripStop: Ember.computed('appState.selectedTripStop', function () {
      if (Ember.isPresent(this.get('selectedTripStopId'))) {
        return this.get('store').peekRecord('trip-stop', this.get('selectedTripStopId'));
      } else {
        return null;
      }
    }),
    addActivity: false,
    invertedWidth: false,
    isVisible: true,
    defaultMode: true,
    isOpeningTicket: false,
    isOpeningReport: false,
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    currentTruck: Ember.computed.alias('appState.currentTruck'),
    askForOdometer: Ember.computed.alias('appConfiguration.truckSettings.odometer_on_trip_level'),
    useMaterialCalculation: Ember.computed.alias('appConfiguration.truckSettings.use_material_calculation'),
    showAtgInformation: Ember.computed.alias('appConfiguration.truckSettings.show_atg_information'),
    deprecatedSourceForTripReport: Ember.computed(function () {
      return this.get('appConfiguration.truckSettings.deprecated_source_trip_report');
    }),

    deprecatedSourceForDriverReport: Ember.computed(function () {
      return this.get('appConfiguration.truckSettings.deprecated_source_driver_report');
    }),
    trips: Ember.computed.alias('model'),
    startedTrip: Ember.computed('model.@each.state', function () {
      return this.get('model').toArray().find(function (trip) {
        return trip.get('state') === 'started';
      });
    }),
    tripIsStarted: Ember.computed('startedTrip', function () {
      return Ember.isPresent(this.get('startedTrip'));
    }),
    selectedTripStopTripIsStarted: Ember.computed('selectedTripStop.trip.state', 'startedTrip', function () {
      if (Ember.isBlank(this.get('selectedTripStop'))) return false;
      return this.get('startedTrip') && this.get('selectedTripStop.trip.state') === 'started';
    }),
    ATGProblemTanksPresent: Ember.computed('selectedTripStop.trip.ATGProblemTSS', function () {
      if (Ember.isBlank(this.get('selectedTripStop'))) return false;
      return Ember.isPresent(this.get('selectedTripStop.trip.ATGProblemTSS'));
    }),
    isATGStop: Ember.computed('selectedTripStop.site.isATGStop', function () {
      if (Ember.isBlank(this.get('selectedTripStop'))) return false;
      return this.get('selectedTripStop.site.isATGStop');
    }),
    remarksForDriverPresent: Ember.computed('selectedTripStop.allRemarks', function () {
      if (Ember.isBlank(this.get('selectedTripStop'))) return false;
      return Ember.isPresent(this.get('selectedTripStop.allRemarks'));
    }),
    selectedMarkers: Ember.computed('selectedTripStop', function () {
      return Ember.A([this.get('selectedTripStop')]);
    }),
    tripMarkers: Ember.computed('selectedTripStop.trip.displayableTripStops.[]', 'selectedTripStop.trip.isFulFilled', function () {

      return this.get('selectedTripStop.trip.displayableTripStops');
    }),
    points: Ember.computed('model', 'model.@each.trip_stops', 'model.isFulfilled', function () {
      var tripStops = [];
      this.get('model').forEach(function (trip) {
        tripStops.concat(trip.get('tripStops'));
      });
      return tripStops;
    }),
    shift: Ember.computed('model.firstObject', function () {
      return this.get('model.firstObject.shift');
    }),
    shiftStateStarted: Ember.computed('model.firstObject.shift.state', function () {
      return this.get('model.firstObject.shift.state') === 'started';
    }),
    currentTruckInventory: Ember.computed('shift', 'currentTruck', function () {
      return this.get('shift.truck_inventories').filterBy('truck_id', this.get('currentTruck'));
    }),
    canChangeTerminal: Ember.computed('startedTrip.hasLoadStop', 'startedTrip.loadStopIsConfirmed', function () {
      return this.get('startedTrip.hasLoadStop') && !this.get('startedTrip.loadStopIsConfirmed');
    }),

    appController: Ember.inject.controller('application'),
    siteStoragesLoader: Ember.computed.alias('appController.siteStoragesLoader'),

    actions: {
      toggleShowTrip: function toggleShowTrip(trip) {
        trip.toggleProperty('isShown');
      },
      toggleInvertedWidth: function toggleInvertedWidth(val) {
        this.set('invertedWidth', val);
      },
      addSpecialActivity: function addSpecialActivity() {
        this.toggleProperty('addActivity');
      },
      updateState: function updateState(action, model) {
        var _this = this;

        this.get('appState').setSelectedTrip(model.id);
        switch (action.state) {
          case 'started':
            // Start and Restart
            if (action.action === 'restart' && model.get('hasSkippedTripStops')) {
              model.get('tripStops').forEach(function (tripStop) {
                return tripStop.set('DriverRemarks', null);
              });
              model.set('ConfirmedEndDT', null);
            }

            this._askForOdometerStart(model).then(function () {
              // trip state change to 'started' is performed at the end of the start task
              if (action.state !== 'started') {
                model.set('state', action.state);
              }

              _this._retrieveStaleSiteStorages(model);

              model.start();
              model.get('shift.trips').forEach(function (trip) {
                return trip.notifyPropertyChange('state');
              });
            });

            break;
          case 'finished':
            // Finish
            this._askForOdometerEnd(model).then(function () {
              model.set('state', action.state);
              model.finish();
            });

            break;
          case 'planned':
            // Cancel
            model.set('state', action.state);
            model.cancel();
        }
      },
      viewTicket: function viewTicket(trip) {
        var _this2 = this;

        var pdfDocument = this.get('store').createRecord('pdf-document', { documentType: 'tripReport',
          modelId: trip.get('id') });

        var flagTripReportOpening = function flagTripReportOpening(opening) {
          _this2.set('isOpeningTicket', opening);
        };
        var errorOpeningDocument = function errorOpeningDocument(error) {
          _this2._errorOpeningDocument(error);
        };

        pdfDocument.open({
          signalOpening: flagTripReportOpening,
          showWarning: errorOpeningDocument
        });
      },
      viewReport: function viewReport(trip) {
        var _this3 = this;

        var flagDriverReportOpening = function flagDriverReportOpening(opening) {
          _this3.set('isOpeningReport', opening);
        };
        var errorOpeningDocument = function errorOpeningDocument(error) {
          _this3._errorOpeningDocument(error);
        };

        this.get('store').query('pdf-document', {
          documentType: 'driverReport',
          modelId: trip.get('id')
        }).then(function (documentsList) {
          // Drop documents with description 'ticket', there is a separate button for that document type
          documentsList = documentsList.rejectBy('description', 'ticket');

          var documentOpenOptions = {
            signalOpening: flagDriverReportOpening,
            showWarning: errorOpeningDocument
          };

          switch (documentsList.length) {
            case 0:
              _this3.get('toast').info(_this3.get('i18n').t('trip.documents.notfound'));
              break;
            case 1:
              documentsList.get('firstObject').open(documentOpenOptions);
              break;
            default:
              _this3._showDocumentSelector(documentsList, documentOpenOptions);
          }
        }).catch(function (error) {
          _this3._errorOpeningDocument(error);
        });
      },
      viewRemarks: function viewRemarks(trip) {
        this.set('selectedTrip', trip);
        this.set('defaultMode', false);
      },
      closeRemarks: function closeRemarks() {
        this.set('defaultMode', true);
      },
      skipTrip: function skipTrip(trip) {
        trip.get('tripStops').forEach(function (tripStop) {
          return tripStop.set('DriverRemarks', 'Skipped');
        });
        trip.set('state', 'finished');
        trip.finish();
      },
      syncTrip: function syncTrip(trip) {
        var _this4 = this;

        var tripId = trip && trip.id;

        if (!tripId || !trip.get('isShown')) {
          return;
        }

        trip.set('beingProcessed', true);

        trip.reload().then(function () {
          return _this4._retrieveUnloadSiteStorages(trip);
        }).finally(function () {
          trip.set('beingProcessed', false);
        });
      }
    },
    _retrieveUnloadSiteStorages: function _retrieveUnloadSiteStorages(trip) {
      if (this.get('internetConnection.isGoodQuality')) {
        var siteIds = trip.deliveryStops().mapBy('SiteID').uniq();
        return this.loadSiteStorages(siteIds);
      } else {
        return Promise.resolve();
      }
    },
    _retrieveStaleSiteStorages: function _retrieveStaleSiteStorages(trip) {
      var siteIds = trip.sitesWithStaleStorages().mapBy('SiteID');
      if (Ember.isPresent(siteIds)) {
        this.loadSiteStorages(siteIds);
      }
    },
    getAllTripStops: function getAllTripStops() {
      var _this5 = this;

      return this.get('store').peekAll('trip-stop').filter(function (tripStop) {
        return tripStop.get('tripID') === _this5.get('trip').id;
      });
    },
    _errorOpeningDocument: function _errorOpeningDocument(error) {
      this.get('toast').error(this.get('i18n').t('trip.documents.error') + error.message);
    },
    _showDocumentSelector: function _showDocumentSelector(documentsList, documentOpenOptions) {
      var _this6 = this;

      var openDocument = function openDocument(document) {
        try {
          document.open(documentOpenOptions);
        } catch (error) {
          _this6._errorOpeningDocument(error);
        }
      };

      var documentButtons = documentsList.map(function (document) {
        return {
          text: _this6.get('i18n').t('trip.documents.' + document.description, { default: document.description }),
          onClick: function onClick() {
            openDocument(document);
          }
        };
      });

      this.get('f7').modal({ text: this.get('i18n').t('trip.documents.dialogText'),
        verticalButtons: true,
        buttons: documentButtons });
    },
    _askForOdometerEnd: function _askForOdometerEnd(trip) {
      var deferred = Ember.RSVP.defer();

      if (!this.get('askForOdometer')) {
        deferred.resolve();
        return deferred.promise;
      }

      var odometer = trip.get('OdometerEnd');
      this.get('f7').extdPrompt(this.get('i18n').t('end_of_trip'), this.get('i18n').t('form_odometer'), 'number', odometer, function (error) {
        if (error !== 'undefined' && error !== '' && error !== '0') {
          if (error !== odometer) {
            trip.set('OdometerEnd', error);
          }

          deferred.resolve();
        }
      }, function () {
        return deferred.reject();
      });

      return deferred.promise;
    },
    _askForOdometerStart: function _askForOdometerStart(trip) {
      var deferred = Ember.RSVP.defer();

      if (!this.get('askForOdometer')) {
        deferred.resolve();

        return deferred.promise;
      }

      var odometer = trip.get('OdometerStart');

      this.get('f7').extdPrompt(this.get('i18n').t('start_of_trip'), this.get('i18n').t('form_odometer'), 'number', odometer, function (error) {
        if (error !== 'undefined' && error !== '' && error !== '0') {
          if (error !== odometer) {
            trip.set('OdometerStart', error);
          }

          deferred.resolve();
        }
      }, function () {
        return deferred.reject();
      });

      return deferred.promise;
    }
  });
});