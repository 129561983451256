define('bottomline-execution/controllers/application', ['exports', 'bottomline-execution/utils/url-utils', 'bottomline-execution/mixins/version'], function (exports, _urlUtils, _version) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_version.default, {
    adrReportController: Ember.inject.controller('shifts.adr-report'),

    queryParams: ['editTruck'],

    session: Ember.inject.service(),
    locationService: Ember.inject.service('location'),
    androidLogging: Ember.inject.service(),
    offlineSyncerService: Ember.inject.service('offline-syncer'),
    i18n: Ember.inject.service(),
    scopedData: Ember.inject.service(),
    androidPermissions: Ember.inject.service(),
    internetConnection: Ember.inject.service(),
    applicationReload: Ember.inject.service(),

    isGeneratingAdrReport: false,

    _setup: Ember.on('init', function () {
      this.get('applicationReload');
      this.get('locationService').set('store', this.get('store'));
      this.get('scopedData').set('store', this.get('store'));
      this.get('offlineSyncerService').set('store', this.get('store'));
      this.get('androidLogging'); // Initialize service
      this.get('internetConnection').set('store', this.get('store'));
    }),

    currentTruckChanged: Ember.observer('model.currentTruck', function () {
      this.get('locationService').set('truckId', this.get('model').get('currentTruck'));
    }),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('model'),
    truckSettings: Ember.computed.alias('appConfiguration.truckSettings'),
    isShiftIndex: Ember.computed.equal('currentPath', 'shifts.index'),
    withinShift: Ember.computed('currentPath', function () {
      return this.get('currentPath').indexOf('trips') > -1;
    }),
    blockExit: Ember.computed('currentPath', function () {
      return this.get('currentPath').indexOf('trips') > -1 || this.get('currentPath').indexOf('adr') > -1;
    }),
    canGenerateADRReport: Ember.computed('internetConnection.isBadQuality', 'appState.anyShiftStarted', 'isGeneratingAdrReport', function () {
      return !this.get('internetConnection.isBadQuality') && this.get('appState.anyShiftStarted') && !this.get('isGeneratingAdrReport');
    }),
    backendEnvironment: Ember.computed('appState.alias', function () {
      var url = this.get('appState.domain');
      var alias = this.get('appState.alias');
      var domain = _urlUtils.default.extractDomainName(url);

      if (_urlUtils.default.isIpAddress(domain)) {
        // If the domain is an IP address, show it as-is
        return domain;
      } else {
        if (Ember.isPresent(alias)) {
          return alias;
        } else {
          return '';
        }
      }
    }),
    truckSelected: Ember.computed('appState.currentTruck', function () {
      return Ember.isPresent(this.get('appState').getTruck());
    }),
    currentTruck: Ember.computed('appState.currentTruck', function () {
      return this.get('appState').getTruck().get('identifier');
    }),
    doesTrailerExist: Ember.computed('appState.currentTrailer', function () {
      return Ember.isPresent(this.get('appState').getTrailer());
    }),
    currentTrailer: Ember.computed('appState.currentTrailer', function () {
      return this.get('appState').getTrailer().get('identifier');
    }),
    canOpenShifts: Ember.computed('appState.currentTruck', function () {
      return Ember.isPresent(this.get('appState.currentTruck'));
    }),
    actions: {
      back: function back() {
        window.history.back();
      },
      navigate: function navigate(path) {
        switch (path) {
          case 'shifts.index':
            this.transitionToRoute('edit-truck');
            break;
          case 'shifts.show.trips.index':
            alert(this.get('i18n').t('back_button_not_supported'));
            break;
          case 'shifts.show.trips.show.trip-stops.show.index':
            alert(this.get('i18n').t('back_button_not_supported'));
            break;
          default:
        }
      },
      adrReport: function adrReport() {
        var _this = this;

        var useDeprecatedSource = this.get('truckSettings.deprecated_source_adr_report');
        var adrReportController = this.get('adrReportController');

        if (useDeprecatedSource && adrReportController.canGenerateADRReport()) {
          if (useDeprecatedSource == 'jsreport') {
            this.set('isGeneratingAdrReport', true);
            adrReportController.generateADRReport().then(function () {
              return _this.set('isGeneratingAdrReport', false);
            });
          } else if (useDeprecatedSource == 'on-screen') {
            // open the page in the app with ADR information
            this.transitionToRoute('shifts.adr-report', this.get('appState.selectedShift'));
          }
        } else {
          var truckId = this.get('appState.currentTruck');
          var flagOpeningAdrReport = function flagOpeningAdrReport(opening) {
            _this.set('isGeneratingAdrReport', opening);
          };
          var errorOpeningDocument = function errorOpeningDocument(_error) {
            _this.get('f7').alert(_this.get('i18n').t('truck.documents.notfound'), '');
          };

          var pdfDocument = this.get('store').createRecord('pdf-document', { documentType: 'adrReport',
            modelId: truckId });

          pdfDocument.open({
            signalOpening: flagOpeningAdrReport,
            showWarning: errorOpeningDocument
          });
        }
      },
      sync: function sync() {
        this.get('f7').confirm(this.get('i18n').t('sync.warning'), this.get('i18n').t('sync.title'), Ember.run.bind(this, function () {
          window.location.reload();
        }));
      }
    }
  });
});