define('bottomline-execution/serializers/pdf-document', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Serializer = _emberData.default.Serializer;
  exports.default = Serializer.extend({
    normalizeResponse: function normalizeResponse(_store, _primaryModelClass, payload, _id, _requestType) {
      var mappedPdfDocuments = payload['documents'].map(function (document) {
        return {
          id: document.id,
          type: 'pdf-document',
          attributes: {
            description: document.description.toLowerCase()
          }
        };
      });

      return { data: mappedPdfDocuments };
    }
  });
});