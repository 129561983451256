define('bottomline-execution/utils/i18n/missing-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (locale, key, context) {
    var values = Object.keys(context).map(function (key) {
      return context[key];
    });
    if (context.default) {
      return context.default;
    } else {
      return '~' + key + ': ' + values.join(', ');
    }
  };
});