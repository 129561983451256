define('bottomline-execution/resolver', ['exports', 'ember-resolver', 'bottomline-execution/mixins/raw-app-state'], function (exports, _emberResolver, _rawAppState) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberResolver.default.extend(_rawAppState.default, {
    resolve: function resolve(fullName) {
      var parsedName = this.parseName(fullName);

      switch (parsedName.type) {
        case 'model':
          fullName = this._modelNameFor(parsedName.name);
          break;
        case 'serializer':
          fullName = this._serializerFor(parsedName.name);
          break;
        case 'adapter':
          fullName = this._adapterFor(parsedName.name);
          break;
      }

      fullName = fullName || parsedName.type + ':' + parsedName.name;

      return this._super(fullName);
    },


    _beAdapters: {
      specialized: ['pdf-document', 'picture']
    },

    _bxAdapters: {
      noOperation: ['adr-material', 'extra-time', 'inventory-correction', 'material-seller', 'otc-material', 'picture', 'trailer', 'trip-stop-truck-storage'],
      specialized: ['end-inventory', 'material', 'shift', 'storage', 'trip-stop', 'trip', 'truck']
    },

    _sharedAdapters: ['state', 'truck-setting'],

    _bxSpecificModels: ['customer', 'end-inventory', 'inventory-correction', 'trip-stop-storage', 'trip-stop-truck-storage', 'trip-stop'],

    _beSpecificModels: ['customer', 'trip-stop'],

    _backendSpecificSerializers: ['end-inventory', 'shift', 'site', 'storage', 'trip', 'trip-stop', 'trip-stop-storage', 'trip-stop-truck-storage', 'truck', 'truck-inventory', 'truck-storage'],

    _adapterFor: function _adapterFor(adapterName) {
      if (this._sharedAdapters.includes(adapterName)) return 'adapter:' + adapterName;

      if (this.get('useBxBackend')) {
        return this._bxAdapterFor(adapterName);
      } else {
        return this._beAdapterFor(adapterName);
      }
    },
    _bxAdapterFor: function _bxAdapterFor(adapterName) {
      if (this._bxAdapters.noOperation.includes(adapterName)) return 'adapter:bx/no-op';
      if (this._bxAdapters.specialized.includes(adapterName)) return 'adapter:bx/' + adapterName;
      return 'adapter:bx/base-adapter';
    },
    _beAdapterFor: function _beAdapterFor(adapterName) {
      if (this._beAdapters.specialized.includes(adapterName)) return 'adapter:be/' + adapterName;
      return 'adapter:be/base-adapter';
    },
    _modelNameFor: function _modelNameFor(modelName) {
      if (this.get('useBxBackend')) {
        if (this._bxSpecificModels.includes(modelName)) return 'model:bx/' + modelName;
      } else {
        if (this._beSpecificModels.includes(modelName)) return 'model:be/' + modelName;
      }
    },
    _serializerFor: function _serializerFor(serializerName) {
      if (this._backendSpecificSerializers.includes(serializerName)) {
        if (this.get('useBxBackend')) return 'serializer:bx/' + serializerName;
      }
    }
  });
});