define('bottomline-execution/components/trip-map-osm', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ShowAllPopupsControl = L.Control.extend({
    options: {
      position: 'topleft'
    },

    onAdd: function onAdd(map) {
      var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');
      container.style.backgroundImage = 'url(assets/info.png)';
      container.style.backgroundRepeat = 'no-repeat';
      container.style.backgroundColor = 'white';
      container.style.backgroundPosition = 'center';
      container.style.width = '30px';
      container.style.height = '30px';
      container.style.opacity = 0.5;

      container.onclick = function () {
        var currentToggleStatus = map.component.get('showingAll');
        var currentMarkers = map.component.get('currentMarkers');

        container.style.opacity = currentToggleStatus ? 1 : 0.5;
        if (Ember.isEmpty(currentMarkers)) {
          return;
        }
        if (currentToggleStatus) {
          currentMarkers.forEach(function (item) {
            return item.openPopup();
          });
        } else {
          currentMarkers.forEach(function (item) {
            return item.closePopup();
          });
        }

        map.component.set('showingAll', !currentToggleStatus);
      };
      return container;
    }
  });

  exports.default = Ember.Component.extend({
    locationService: Ember.inject.service('location'),
    assetMap: Ember.inject.service('asset-map'),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    showingAll: false,

    classNames: ['map'],
    points: [],
    markers: [],
    selectedMarkers: [],
    currentLocationMarker: null,
    invertedWidth: false,
    mapScrollable: true,
    centerToTruck: false,
    zoom: 15,
    fitSelectedEnabled: false,
    markersChanged: Ember.observer('markers.@each.latitude', 'markers.@each.trip.state', function () {
      this.renderPoints({ fit: true, center: true });
    }),
    locationChanged: Ember.observer('locationService.lat', function () {
      // this.centerMap();
    }),

    sizeChanged: Ember.observer('invertedWidth', function () {
      var _this = this;

      setTimeout(function () {
        _this.get('leafletMap').invalidateSize();
      }, 400);
    }),
    currentLocationChanged: Ember.observer('locationService.lat', 'locationService.lng', 'markers.[]', function () {
      if (this.get('locationService.lat')) {
        this.set('currentLocationMarker', this.getCurrentLocationMarker());
        this.getCurrentLocationMarker().addTo(this.get('leafletMap'));
      }
    }),

    highlightMarkerOnChange: Ember.observer('selectedMarkers', function () {
      var _this2 = this;

      this.get('markers').forEach(function (t) {
        if (Ember.isPresent(t.get('tsMarker'))) {
          var isSelected = _this2._isSelected(t);
          t.get('tsMarker').setIcon(_this2.markerIcon(_this2.getIconPath(t, isSelected)));
        }
      });
    }),

    _isSelected: function _isSelected(tripStop) {
      return Ember.A(this.get('selectedMarkers')).includes(tripStop);
    },


    selectedTrip: Ember.computed.alias('parentController.selectedTrip'),
    selectedShift: Ember.computed.alias('parentController.selectedShift'),

    leafletMap: Ember.computed('selectedTrip', function () {
      var _this3 = this;

      var lmap = new L.Map('leafletmap', {
        scrollWheelZoom: this.get('mapScrollable'),
        center: new L.LatLng(this.get('locationService.lat'), this.get('locationService.lng')),
        zoom: this.get('zoom'),
        layers: new L.TileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png')
      });

      lmap.component = this;

      lmap.addControl(new L.Control.Fullscreen());
      lmap.addControl(new ShowAllPopupsControl());

      lmap.on('resize', function () {
        _this3.renderPoints();
      });

      return lmap;
    }),

    renderMap: Ember.on('didInsertElement', function () {
      var _this4 = this;

      this.get('locationService');
      setTimeout(function () {
        _this4.initCurrentLocation();
        _this4.renderPoints();
        _this4.centerMap();
      });
    }),

    renderPoints: function renderPoints() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { fit: true, center: true };

      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      var selectedMarkers = this.get('selectedMarkers');
      var markers = this.get('markers');
      var leafletMap = this.get('leafletMap');

      if (leafletMap) {
        this.removeMarkers();
      }

      if (Ember.isEmpty(markers)) {
        return [];
      }

      var points = this._getAllDrawableMarkers(markers, selectedMarkers);

      // TODO: is the condition required?
      if (this.get('leafletMap')) {
        this.addMarkers(points);
        this.set('currentMarkers', points);

        if (options.center) {
          this.centerMap();
        }

        if (options.fit) {
          this.fitSelected();
        }
      }
    },
    _getAllDrawableMarkers: function _getAllDrawableMarkers(markers) {
      var _this5 = this;

      return markers.reduce(function (result, tripStop) {
        var isSelected = _this5._isSelected(tripStop);

        _this5._getTripStopStorageMarkersForTripStop(tripStop, result, isSelected);
        _this5._getTripStopMarker(tripStop, result, isSelected);

        return result;
      }, []);
    },
    getIconPath: function getIconPath(tripStop, isSelected) {
      var isTank = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var iconType = this._iconType(tripStop);
      var iconFileNameParts = [iconType];
      var isStation = iconType == 'fillingstation';

      isTank && isStation ? iconFileNameParts.push('tank') : null;
      isSelected ? iconFileNameParts.push('green') : null;
      tripStop.get('isConfirmed') ? iconFileNameParts.push('completed') : null;

      return this.get('assetMap').resolve('assets/' + iconFileNameParts.join('-') + '.png');
    },
    getLatLng: function getLatLng() {
      return [this.get('locationService.lat'), this.get('locationService.lng')];
    },
    getCurrentLocationMarker: function getCurrentLocationMarker() {
      var marker = this.get('currentLocationMarker');
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      if (!Ember.isPresent(marker)) {
        marker = L.marker(this.getLatLng(), { icon: this.markerIcon('assets/truck.png') });
        this.set('currentLocationMarker', marker);
      } else {
        this.set('currentLocationMarker', marker.setLatLng(this.getLatLng()));
      }

      marker.addTo(this.get('leafletMap')).update();

      return marker;
    },
    markerIcon: function markerIcon(iconPath) {
      return L.icon({
        iconUrl: iconPath
      });
    },
    addMarkers: function addMarkers(markers) {
      var _this6 = this;

      markers.forEach(function (marker) {
        marker.addTo(_this6.get('leafletMap'));
      });
    },
    _iconType: function _iconType(tripStop) {
      if (tripStop.get('isLoad') || tripStop.get('isCorrection')) return 'depot';
      if (tripStop.get('isParking')) return 'parking';
      return 'fillingstation';
    },
    removeMarkers: function removeMarkers() {
      var markers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('currentMarkers');

      if (markers) {
        markers.forEach(function (marker) {
          marker.remove();
        });
      }
    },
    initCurrentLocation: function initCurrentLocation() {
      if (this.get('locationService') && this.get('locationService.lat')) {
        this.getCurrentLocationMarker().addTo(this.get('leafletMap'));

        this.fitSelected();
      }
    },
    centerMap: function centerMap() {
      var _this7 = this;

      if (this.get('centerToTruck') && Ember.isPresent(this.get('locationService.lat'))) {
        this.get('leafletMap').panTo(new L.LatLng(this.get('locationService.lat'), this.get('locationService.lng')));
        this.get('leafletMap').setZoom(this.get('zoom'));
        this.set('center', false);
      } else {
        if (Ember.isEmpty(this.get('markers'))) {
          Ember.run.later(function () {
            _this7.centerMap();
          }, 30000);
          return;
        }
        var corners = this.get('markers').toArray().filter(function (marker) {
          return _this7._hasLatitudeLongitude(marker) && _this7._isUndeliveredUnload(marker);
        }).map(function (marker) {
          return new L.latLng(marker.get('latitude'), marker.get('longitude'));
        });

        var bounds = L.latLngBounds(corners);
        if (corners.length > 0) {
          this.get('leafletMap').fitBounds(bounds, { padding: [50, 50] });
        }
      }
    },
    fitSelected: function fitSelected() {
      if (!this.get('fitSelectedEnabled')) {
        return;
      } else if (this.get('selectedMarker') && this.get('locationService.lat')) {
        var _L;

        var corners = [new L.latLng(this.get('selectedMarker.latitude'), this.get('selectedMarker.longitude')), new L.latLng(this.get('locationService.lat'), this.get('locationService.lng'))];

        var bounds = (_L = L).latLngBounds.apply(_L, corners);
        this.fitBounds(bounds);
      } else {
        this.fitZoom();
      }
    },
    fitBounds: function fitBounds(bounds) {
      if (this.get('markers').toArray().length > 0) {
        this.get('leafletMap').fitBounds(bounds);
      }
    },
    fitZoom: function fitZoom() {
      var _this8 = this;

      // in production it somehow markers sometimes get undefined
      if (Ember.isEmpty(this.get('markers'))) {
        return;
      }

      var corners = this.get('markers').toArray().filter(function (marker) {
        return _this8._hasLatitudeLongitude(marker);
      }).map(function (marker) {
        return new L.latLng(marker.get('latitude'), marker.get('longitude'));
      });

      corners.push(new L.latLng(this.get('locationService.lat'), this.get('locationService.lng')));
      var bounds = L.latLngBounds(corners);

      this.fitBounds(bounds);
    },
    _hasLatitudeLongitude: function _hasLatitudeLongitude(item) {
      return Ember.isPresent(item) && Ember.isPresent(item.get('latitude')) && Ember.isPresent(item.get('longitude'));
    },
    _getTripStopStorageMarkersForTripStop: function _getTripStopStorageMarkersForTripStop(tripStop, result, isSelected) {
      var _this9 = this;

      if (this._hasLatitudeLongitude(tripStop) && tripStop.get('trip_stop_storages.length')) {
        tripStop.get('trip_stop_storages').forEach(function (tss) {

          if (_this9._hasLatitudeLongitude(tss.get('storage'))) {
            var showTSSMarker = tripStop.get('latitude') + '' !== tss.get('storage.latitude') + '' || tripStop.get('longitude') + '' !== tss.get('storage.longitude') + '';

            if (showTSSMarker) {
              var tssMarker = new L.marker([tss.get('storage.latitude'), tss.get('storage.longitude')], {
                title: tripStop.get('sitename'),
                zIndexOffset: isSelected ? 998 : 887,
                icon: _this9.markerIcon(_this9.getIconPath(tripStop, isSelected, true))
              }).on('click', function () {
                _this9._selectTripStop(tripStop.id);
              });

              _this9._bindPopup(tssMarker, tripStop);

              tss.set('tssMarker', tssMarker);
              result.push(tssMarker);
            }
          }
        });
      }
    },
    _getTripStopMarker: function _getTripStopMarker(tripStop, result, isSelected) {
      var _this10 = this;

      if (this._hasLatitudeLongitude(tripStop)) {
        var tsMarker = new L.marker([tripStop.get('latitude'), tripStop.get('longitude')], {
          title: tripStop.get('sitename'),
          zIndexOffset: isSelected ? 999 : 888,
          icon: this.markerIcon(this.getIconPath(tripStop, isSelected)),
          opacity: this._getOpacity(tripStop.get('isConfirmed'))
        }).on('click', function () {
          _this10._selectTripStop(tripStop.id);
        });

        this._bindPopup(tsMarker, tripStop);

        tripStop.set('tsMarker', tsMarker);
        result.push(tsMarker);
      }
    },
    _selectTripStop: function _selectTripStop(tripStopId) {
      this.get('appState').setSelectedTripStop(tripStopId);
    },
    _isUndeliveredUnload: function _isUndeliveredUnload(marker) {
      return marker.get('isUnload') && !marker.get('isConfirmed');
    },
    _getOpacity: function _getOpacity(isConfirmed) {
      return isConfirmed ? 0.5 : 1.0;
    },
    _bindPopup: function _bindPopup(marker, tripStop) {
      var tripStopDescription = this._tripStopDescription(tripStop);
      marker.bindPopup(tripStopDescription, { autoClose: false, closeButton: false, offset: [20, 10], closeOnClick: false, popupAnchor: [20, 20], autoPan: false }).openPopup();
    },
    _tripStopDescription: function _tripStopDescription(tripStop) {
      var materialNamesList = tripStop.get('plannedMaterials').reduce(function (collector, material) {
        return collector + '<br />' + material.get('MaterialNameShort');
      }, '');
      return tripStop.get('sitename') + materialNamesList;
    }
  });
});