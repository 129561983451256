define('bottomline-execution/models/pdf-document', ['exports', 'ember-data', 'bottomline-execution/utils/guid-util'], function (exports, _emberData, _guidUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    cordovaPlatform: Ember.inject.service('ember-cordova/platform'),
    ajax: Ember.inject.service(),

    description: attr('string'),
    documentType: attr('string'),
    modelId: attr('string'),

    open: function open() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { signalOpening: null, showWarning: null };
      var signalOpening = options.signalOpening,
          showWarning = options.showWarning;


      if (this.get('cordovaPlatform.isCordova')) {
        this._openOnTablet(signalOpening, showWarning);
      } else {
        this._openInBrowser(signalOpening, showWarning);
      }
    },
    _openInBrowser: function _openInBrowser(signalOpening, showWarning) {
      var url = this._getDownloadUrl();
      var headers = this.get('ajax.headers');

      if (signalOpening) signalOpening(true);
      fetch(url, { headers: headers }).then(function (res) {
        return res.blob();
      }).then(function (blob) {
        if (blob.size === 0) {
          throw new Error('size is 0');
        }
        window.open(window.URL.createObjectURL(blob), '_blank', 'location=no');
      }).catch(function (error) {
        console.warn('Error retrieving document from backend:', error);
        var errorMessage = error.http_status || error.message;
        if (showWarning) showWarning({ message: 'Cannot open file (' + errorMessage + ')' });
      }).finally(function () {
        if (signalOpening) signalOpening(false);
      });
    },
    _openOnTablet: function _openOnTablet(signalOpening, showWarning) {
      if (signalOpening) signalOpening(true);

      var url = this._getDownloadUrl();
      var fileName = (0, _guidUtil.guidUtil)() + '.pdf';
      var filePath = cordova.file.externalDataDirectory + fileName;

      var fileTransfer = new FileTransfer();
      fileTransfer.download(encodeURI(url), filePath, function (fileEntry) {
        Ember.run.later(function () {
          if (signalOpening) signalOpening(false);
        }, 2000);
        cordova.plugins.fileOpener2.open(fileEntry.toInternalURL(), 'application/pdf');
      }, function (error) {
        if (signalOpening) signalOpening(false);
        console.warn('Error retrieving document from backend:', error);
        if (showWarning) showWarning({ message: 'Cannot open file (' + error.http_status + ')' });
      }, null, {
        headers: this.get('ajax.headers')
      });
    },
    _getDownloadUrl: function _getDownloadUrl() {
      var snapshot = this._internalModel.createSnapshot();
      return this.get('store').adapterFor('pdf-document').urlForDownloadRecord(snapshot);
    }
  });
});